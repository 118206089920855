<template>
    <div>
        <v-app>
            <v-dialog v-model="showDialog" max-width="1000px">
                <v-card v-if="showDialog">
                    <v-card-title> <span>Referencia {{order_reference}} - Registros</span> </v-card-title>
                    <v-card-text>
                        <template>
                            <v-simple-table v-if="invoiceLogData.length">
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th class="text-left">Fecha consulta</th>
                                            <th class="text-left">Request</th>
                                            <th class="text-left">Response</th>
                                            <th class="text-left">Mensaje</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="item in invoiceLogData" :key="item.id" >
                                            <td>{{ $moment(item.created_at).format('DD-MM-YYYY HH:mm') }}</td>
                                            <td><v-btn color="blue lighten-2" @click="openInvoiceLogRequestResponse({requestResponseInvoiceLog: item.request, type: 'Request'})"> Ver Request </v-btn></td>
                                            <td><v-btn color="blue lighten-2" @click="openInvoiceLogRequestResponse({requestResponseInvoiceLog: item.response, type: 'Response'})"> Ver Response </v-btn></td>
                                            <td>{{ item.message }}</td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                            
                            <v-simple-table v-else><template v-slot:default><thead><tr><th>Sin Registros</th></tr></thead></template></v-simple-table>
                        </template>
                    </v-card-text>
                    <v-card-actions><v-btn color="primary" text @click="showDialog = false" > Cerrar </v-btn></v-card-actions>
                </v-card>
            </v-dialog>
        </v-app>
        <dialog-invoice-log-request-response></dialog-invoice-log-request-response>
    </div>
</template>

<script>
import DialogInvoiceLogRequestResponse from "@/views/oms/Facturaciones/DialogInvoiceLogRequestResponse";
export default {
    components: {
        DialogInvoiceLogRequestResponse
    },
    data(){
        return {
            showDialog: false,
            order_reference: undefined,
            invoiceLogData: [],
            orderSubStatesList: [],
        }
    },
    created(){        
        this.$parent.$on('openDialogInvoicesLogs', (datos) => {
            this.order_reference = datos.order_reference;
            this.fetchData(datos);
        });
    },
    methods: {
        fetchData(datos){
            var vm = this;
            this.axios({
                url: 'invoices/invoice_logs/invoice_id/'+datos.id
            }).then( response => {
                vm.invoiceLogData = response.data;
                vm.showDialog = true; 
            }).catch( (error) => {
                console.log(error);
            });
        },
        openInvoiceLogRequestResponse(data){
            this.$emit("openDialogInvoiceLogRequestResponse", data);
        }
    }
}
</script>
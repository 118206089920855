<template>
  <div>
    <v-app>
      <div class="row dashboard_facturaciones">
        <div class="col">
          <v-card>
            <v-card-title>
                Facturaciones en el sistema <v-spacer></v-spacer>
                <v-btn
                @click="clearSelected"
                v-show="selected.length"
                color="error"
                class="float-center mr-3"
                ><v-icon small center dark>mdi-delete</v-icon>Limpiar selección</v-btn>
                <v-btn @click="printMassiveInvoices" color="success" class="float-left" :disabled="!selected.length" >
                    <v-icon left dark>mdi-format-list-checks</v-icon>Imprimir Seleccionadas  ({{selected.length}})
                </v-btn>
                <v-btn class @click="fetchDataPaginated" text icon color="blue"> <v-icon>mdi-refresh</v-icon> </v-btn>
            </v-card-title>


            <v-card-text>
                <v-card outlined tile elevation="1">
                    <v-card-text style="padding-bottom: 0px;">
                        <div class="d-flex justify-space-between">
                            <div class="d-flex flex-column col-10">
                                <v-text-field v-model="search" clearable label="Búsqueda" hide-details  solo class="mr-2" @change="fetchDataPaginated"></v-text-field>
                            </div>
                            <div class="d-flex flex-column col-2">
                                <v-btn class="btn-starkoms-primary mx-2" dark style="min-width: 138px;" @click="fetchDataPaginated" >Buscar</v-btn>
                            </div>
                            
                        </div>
                        <div class="d-flex justify-space-between">
                            <div class="d-flex flex-column col-4">
                                <span class="text-h6 font-weight-regular black--text"><v-icon>mdi-calendar</v-icon> Filtro por Fecha</span>
                                <v-menu
                                    ref="menu1" v-model="menu1" :close-on-content-click="false" transition="scale-transition" offset-y
                                    max-width="290px" min-width="290px" style="border-bottom: 1px solid #000;" >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field class="mr-2"  v-bind="attrs" v-on="on" clearable @click:clear="limpiarRango" v-model="dateRangeText" placeholder="Fechas" solo readonly ></v-text-field>
                                    </template>
                                    <v-date-picker id="datepicker-starkoms" @input="formatearRango" range v-model="dates" ></v-date-picker>
                                </v-menu>
                            </div>
                            <div class="d-flex flex-column col-3">
                                <span class="text-h6 font-weight-regular black--text"><v-icon>mdi-calendar</v-icon> Filtro por Estado</span>
                                <v-select class="ml-2 mr-2" label="Filtro por estado" :items="invoiceStateList" v-model="selectInvoiceState" item-text="name" solo ></v-select>
                            </div>
                            <div class="d-flex flex-column source_filter col-3">
                                <span class="text-h6 font-weight-regular black--text "><v-icon>mdi-store</v-icon> Filtro por canal</span>
                                <v-autocomplete
                                    v-model="selectSources"
                                    :items="sourcesList"
                                    chips
                                    clearable
                                    deletable-chips
                                    label=""
                                    multiple
                                    item-text="name"
                                    item-value="id"
                                    v-on:change="checkSelection"
                                    >
                                    <template v-slot:item="data">
                                        <template>
                                        <v-list-item-content>
                                            <v-list-item-title v-html="data.item.name"></v-list-item-title>
                                        </v-list-item-content>
                                        </template>
                                    </template>
                                    </v-autocomplete>

                            </div>
                            <div class="d-flex flex-column col-2">
                                <v-btn v-show="!!search || !!dateRangeText" @click="limpiarFiltros" class="mx-2" dark color="grey" >Limpiar Filtros</v-btn>
                            </div>
                        </div>
                    </v-card-text>
                </v-card>
            </v-card-text>
            
            <v-card-text>
              <v-data-table
                :headers="headers" 
                :items="tableData" 
                show-select
                v-model="selected"
                no-results-text="No hay resultados disponibles" 
                no-data-text="No hay datos disponibles"
                loading-text="Cargando datos..." 
                :sort-by="'id'" 
                :sort-desc="true" 
                class="elevation-1 datatable"
                :loading="dataTableLoading" 
                :disable-pagination="true" 
                :hide-default-footer="true" 
                :items-per-page="selectItemsPerPage"
                :disable-sort="true" 
                @toggle-select-all="selectAllToggle"
              >
                <template v-slot:item.data-table-select="{ item, isSelected, select }">
                    <v-simple-checkbox
                        :value="isSelected"
                        v-if="item.invoice_state.type == 'DEFAULT_INVOICE_OUT'"
                        @input="select($event)"
                        :ripple="false"
                    ></v-simple-checkbox>
                </template>
                <template v-slot:item.created_at="{ item }">
                    <td class="text-center" >{{$moment(item.created_at).format('LLLL')}}</td>
                </template>
                <template v-slot:item.current_state="{ item }">
                  <td class="text-center">
                    <button size="sm" v-if="item.invoice_state.type == 'DEFAULT_INVOICE_OUT'"  class="btn btn-sm btn-primary">{{item.invoice_state.name}}</button>                            
                    <button size="sm" v-if="item.invoice_state.type == 'DEFAULT_INVOICE_ERROR'"  class="btn btn-sm btn-danger">{{item.invoice_state.name}}</button>
                    <button size="sm" v-if="item.invoice_state.type == 'DEFAULT_INVOICE_PENDING'"  class="btn btn-sm btn-warning">{{item.invoice_state.name}}</button>

                  </td>
                </template>
                <template v-slot:item.acciones="{ item }">
                  <td>
                    <v-btn small class="m-1" color="success" dark :href=item.pdf target="_blank" v-if="item.invoice_state.type == 'DEFAULT_INVOICE_OUT'">
                        <v-icon left dark>mdi-file</v-icon> Ver PDF
                    </v-btn>
                    <v-btn small class="m-1"  color="warning" href="#" v-if="item.invoice_state.type == 'DEFAULT_INVOICE_ERROR'" @click="openDialogInvoicesLogs(item)">
                        <v-icon left dark>mdi-file</v-icon> Ver Registros
                    </v-btn>
                    <br />
                    <v-btn small class="m-1" color="warning" href="#" v-if="item.invoice_state.type == 'DEFAULT_INVOICE_ERROR' && item.invoice_number != ''" @click="retryGetDocument(item)">
                        <v-icon left dark>mdi-file</v-icon> Reintenar Obtener Documento
                    </v-btn>
                  </td>
                </template>              
              </v-data-table>
              <div class="d-flex justify-space-between mt-4">
                  <v-select style="max-width: 120px" @input="fetchDataPaginated" v-model="selectItemsPerPage" label="Registros por página" :items="[5,10,20,50,100]"></v-select>
                  <v-pagination @input="fetchDataPaginated" v-model="page" :length="totalPages"></v-pagination>
              </div>
              
              <br />
              <br />              
            </v-card-text>
          </v-card>
        </div>
      </div>
    </v-app>
    <dialog-invoices-logs></dialog-invoices-logs>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import format from 'date-fns/format';
import DialogInvoicesLogs from "@/views/oms/Facturaciones/DialogInvoicesLogs";
export default {
  components: {
    DialogInvoicesLogs,
  },
    data () {
        return {
            search: '',
            selected: [],
            tableData: [],
            dates: [],
            menu1: false,
            dateRangeText: '',
            page: 1,
            pageCount: 0,
            itemsPerPage: 10,
            dateFormat: "D MMM",
            dateOne: "",
            dateTwo: "",
            totalPages: 1,
            dataTableLoading: false,
            selectItemsPerPage: 10,
            api_url: process.env.VUE_APP_API_URL,
            invoiceStateList: [],
            selectInvoiceState: "Todos",
            sourcesList: [],
            selectSources: [],
            disabledCount: 0,

        }
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
            { title: "Dashboard Facturaciones", route: "/facturaciones" },
        ]);
        this.fetchInvoicesStates();
        this.fetchSources();
        this.fetchDataPaginated();
        
    },
    methods: {
        limpiarFiltros() {
            this.search = "";
            this.dateRangeText = "";
            this.fetchDataPaginated();
        },
        fetchDataPaginated(){
            this.dataTableLoading = true;
            var vm = this;
            const sortBy = 'id';
            const sortDesc = true;
            const page = this.page;
            const itemsPerPage = this.selectItemsPerPage;
            const search = this.search;
            const startDate = !(this.dateRangeText.split(' ~ ').length == 2) ? null : this.dateRangeText.split(' ~ ')[0];
            const endDate = !(this.dateRangeText.split(' ~ ').length == 2) ? null : this.dateRangeText.split(' ~ ')[1];
            const filterState = this.invoiceStateList.find(e => e.name == this.selectInvoiceState) ? this.invoiceStateList.find(e => e.name == this.selectInvoiceState).id : null;
            const filterSources = this.selectSources;

            this.axios({
                url: "invoices/paginated/json",
                method: "GET",
                params: {
                    page,
                    perPage: itemsPerPage,
                    sortBy: sortBy,
                    sortDesc: sortDesc ? 1 : 0,
                    search,
                    startDate,
                    endDate,
                    filterState,
                    filterSources
                },
            })
            .then((response) => {
                let arreglo = [];
                if(response.data.data instanceof Object){
                    Object.keys(response.data.data).forEach(key => {
                        arreglo.push(response.data.data[key]);
                    });
                    vm.tableData = arreglo;
                }else{
                    vm.tableData = response.data.data.map(e => e);
                }
                vm.totalPages = response.data.last_page;
                vm.disabledCount = vm.tableData.filter(e => e.invoice_state.type != "DEFAULT_INVOICE_OUT").length;
                vm.dataTableLoading = false;
            })
            .catch((error) => {
                console.log(error);
                vm.dataTableLoading = false;
            });
        },
        fetchData()
        {
            var vm = this;
            this.axios({
                url: 'invoices/json', 
                method: 'GET',
                params: {
                    "pagination[page]": "1",
                    "pagination[pages]": "24",
                    "pagination[perpage]": "10",
                    "pagination[total]": "234",
                    "sort[sort]": "asc",
                    "sort[field]": "id",
                    "query": ""
                }
            }).then( response => {
                vm.tableData = response.data.data;
            }).catch( error => {
                console.log(error);
            })
        },
        filtroRangoFechas(value, search, item){
            if( !this.dateRangeText || !this.dates.length ){
                return true;
            }else{
                if( this.$moment(value).isSameOrAfter(this.$moment(this.dates[0])) && this.$moment(value).isSameOrBefore(this.$moment(this.dates[1])) ){
                    return true;
                }else{
                    return false;
                }
            }
        },
        formatearRango() {
            if (!this.dates.length) {
                this.dateRangeText = "";
            } else {
                this.dateRangeText = this.dates.join(" ~ ");
            }
            this.fetchDataPaginated();
        },
        limpiarRango()
        {
            this.dates = [];
        },
        openDialogInvoicesLogs(invoice) {
            this.$emit("openDialogInvoicesLogs", invoice);
        },
        retryGetDocument(invoice) {
            this.dataTableLoading = true;
            var vm = this;
            this.axios({
                url: 'invoices/retry_get_document', 
                method: 'POST',
                data: invoice
            }).then( response => {
                this.fetchDataPaginated();
                this.dataTableLoading = false;
            }).catch( error => {
                console.log(error);
                this.dataTableLoading = false;
            })
        },
        fetchInvoicesStates() {
            var vm = this;
            this.axios({
                url: "invoices/invoices_states/filter_list",
            })
            .then((response) => {
                vm.invoiceStateList = response.data.data;
                vm.invoiceStateList.unshift({ id: null, name: "Todos", order: null });
            })
            .catch((error) => {
                console.log(error);
                vm.dataTableLoading = false;
            });
        },
        printMassiveInvoices() {
            console.log("printMassiveInvoices");
            this.axios({
                url: "invoices/print_massive_invoices", method: "POST",
                data: { invoices: this.selected.map((e) => e.id) },
            })
            .then((response) => {
                this.fetchDataPaginated();
                this.selected = [];
                window.open(response.data.pdf, '_blank');
            })
            .catch((error) => {
                console.log(error);
            });
        },
        fetchSources() {
            var vm = this;

            this.axios({
                url: "admin/stores/default/marketplaces_data",
                method: "GET",
            })
            .then((response) => {
            var mapeado = [];
            var datos = response.data;
            mapeado.push({ id: "Manual", name: "Manual" });
            datos.forEach((item) => {
                mapeado.push({
                id: item.marketplace.name,
                name: item.marketplace.name,
                });
            });
            vm.sourcesList = mapeado;
            })
            .catch((error) => {
            console.log(error);
            vm.dataTableLoading = false;
            });
        },
        checkSelection(){
            var vm = this;
            vm.selectSources.forEach(elem => {
                if(Array.isArray(elem)){
                    vm.selectSources.pop();
                    var isRemove = true;
                    elem.forEach(inelem => {
                        if(!vm.selectSources.includes(inelem)){
                            console.log(inelem);
                            vm.selectSources.push(inelem);
                            isRemove = false;
                        }
                    });
                    console.log(isRemove);
                    if(isRemove){
                        elem.forEach(inelem => {
                            var index = vm.selectSources.indexOf(inelem);
                            if (index !== -1) {
                                vm.selectSources.splice(index, 1);
                            }
                        });
                    }
                }
            });
            this.fetchDataPaginated();
        },
        selectAllToggle(props) {
            if(this.selected.length != this.tableData.length - this.disabledCount) {
                this.selected = [];
                const self = this;
                props.items.forEach(item => {
                if(item.invoice_state.type == 'DEFAULT_INVOICE_OUT') {
                    self.selected.push(item);
                } 
                });
            } else this.selected = [];
        },
        clearSelected() {
            let vm = this;
            vm.selected = [];
        }
    },
    computed: {
        headers(){ 
            return [
                { text: '# de orden',  align: 'left',  sortable: true, value: 'order_id' },
                { text: 'Referencia', value: 'order_reference' },
                { text: 'Invoice number', value: 'invoice_number' },
                { text: 'Tipo', value: 'invoice_type' },
                { text: 'Email', value: 'customer.email'},
                { text: 'Canal', value: 'source' },
                { text: 'Fecha', value: 'created_at' },
                { text: 'Estado', value: 'current_state' },
                { text: 'Actions', value: 'acciones', sortable: false },
            ];
        }
    }
}
</script>
<style>
.source_filter .v-input--selection-controls{
  margin-top: 0px !important;
  margin-right: 10px;
  max-width: 100px;
  font-weight: bold;
}
.source_filter .v-autocomplete.v-input > .v-input__control > .v-input__slot {
  background-color: #f2f2f2 !important;
  padding: 0px 12px;
  border-radius: 4px !important;
}
.source_filter .v-text-field {
  padding-top: 0px;
  margin-top: 0px;
}
.source_filter .theme--light.v-label {
  color: black;
}
.source_filter .v-select__slot{
  margin-top: 8px;
}
.source_filter .theme--light.v-text-field > .v-input__control > .v-input__slot:before{
  border-color: rgba(0, 0, 0, 0);
}
</style>
<template>
    <v-app>
        <v-dialog v-model="dialogShipmentLogRequestResponse" width="600">
            <v-card>
                <v-card-title class="text-h5">{{type}}</v-card-title>
                <v-card-text>
                    <p></p>
                    <p>{{ requestResponseInvoiceLog }}</p>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="dialogShipmentLogRequestResponse = false" > Cerrar </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-app>
</template>

<script>
export default {
    data(){
        return {
            dialogShipmentLogRequestResponse: false,
            requestResponseInvoiceLog: undefined,
            type: '',
        }
    },
    created(){
        this.$parent.$on('openDialogInvoiceLogRequestResponse', (data) => {
            this.requestResponseInvoiceLog = data.requestResponseInvoiceLog;
            this.type = data.type;
            this.dialogShipmentLogRequestResponse = true; 
        });
    }
}
</script>